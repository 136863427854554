<script setup>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

const props = defineProps({
	modelValue: {
		type: Array,
		required: true,
	},
	badgeProps: {
		type: null,
		default: undefined,
	},
	icon: {
		type: String,
		default: 'tabler-bell',
	},
	title: {
		type: String,
	},
})
const emit = defineEmits(['removeItem', 'removeAll', 'modelValue:update'])
const notifications = ref(props.modelValue)

const clickItem = (notification) => {
	navigateTo(notification.to)
	removeItem(notification.id)
}

const removeItem = (notificationId) => {
	notifications.value.forEach((item, index) => {
		if (notificationId === item.id) notifications.value.splice(index, 1)
	})
	emit('modelValue:update', notifications)
	emit('remove', notificationId)
}

const removeAll = () => {
	notifications.value = []
	emit('removeAll')
}
</script>

<template>
	<IconBtn id="notification-btn" v-if="notifications">
		<VBadge
			v-bind="props.badgeProps"
			:model-value="notifications"
			color="error"
			:content="notifications.length"
			class="notification-badge">
			<VIcon size="26" :icon />
		</VBadge>

		<VMenu activator="parent" width="380px" offset="14px" :close-on-content-click="true">
			<VCard class="d-flex flex-column">
				<VCardItem class="notification-section">
					<VCardTitle class="text-lg">
						{{ $t(title) }}
					</VCardTitle>

					<template #append>
						<IconBtn v-show="notifications.length" @click="removeAll()">
							<VIcon icon="tabler-x" />

							<VTooltip activator="parent" location="start">
								{{ $t('notifications.remove_all') }}
							</VTooltip>
						</IconBtn>
					</template>
				</VCardItem>

				<VDivider />

				<PerfectScrollbar
					:options="{ wheelPropagation: false }"
					style="max-block-size: 23.75rem">
					<VList class="notification-list rounded-0 py-0">
						<template
							v-for="(notification, index) in notifications"
							:key="notification.title">
							<VDivider v-if="index > 0" />
							<VListItem lines="one" min-height="66px" class="list-item-hover-class">
								<VListItemTitle
									class="font-weight-medium"
									@click="clickItem(notification)"
									style="cursor: pointer">
									{{ notification.title }}
								</VListItemTitle>

								<span class="text-xs text-disabled">
									{{ notification.subtitle }}
								</span>

								<VListItemSubtitle>
									{{ notification.text }}
								</VListItemSubtitle>

								<template #append>
									<div
										class="d-flex flex-column align-center gap-4"
										@click="removeItem(notification.id)">
										<div style="block-size: 28px; inline-size: 28px">
											<IconBtn size="small" class="visible-in-hover">
												<VIcon size="20" icon="tabler-x" />
											</IconBtn>
										</div>
									</div>
								</template>
							</VListItem>
						</template>

						<VListItem
							v-show="!notifications.length"
							class="text-center text-medium-emphasis"
							style="block-size: 56px">
							<VListItemTitle>
								{{ $t('notifications.empty') }}
							</VListItemTitle>
						</VListItem>
					</VList>
				</PerfectScrollbar>
			</VCard>
		</VMenu>
	</IconBtn>
</template>

<style lang="scss" scoped>
.notification-section {
	padding: 14px !important;
}

.notification-footer {
	padding: 6px !important;
}

.v-list-item--active {
	background: #f0f1f1 !important;
	color: #726f7b !important;
}

.v-list-item-title {
	color: #726f7b !important;
}

.list-item-hover-class {
	.visible-in-hover {
		display: none;
	}

	&:hover {
		.visible-in-hover {
			display: block;
		}
	}
}

.notification-list.v-list {
	.v-list-item {
		border-radius: 0 !important;
		margin: 0 !important;
	}
}

.notification-badge {
	.v-badge__badge {
		min-width: 18px;
		padding: 0;
		block-size: 18px;
	}
}
</style>
