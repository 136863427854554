import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

export const useLaravelEcho = () => {
	const runtimeConfig = useRuntimeConfig()
	const echoConfig = runtimeConfig.public.laravelEcho

	return new Echo({
		broadcaster: echoConfig.broadcaster,
		key: echoConfig.key,
		wsHost: echoConfig.wsHost,
		wsPort: echoConfig.wsPort,
		wssPort: echoConfig.wssPort,
		forceTLS: echoConfig.forceTLS,
		encrypted: echoConfig.encrypted,
		authEndpoint: runtimeConfig.public.apiBaseUrl + '/broadcasting/auth',
		bearerToken: authToken(),
		cluster: 'cluster',
		enabledTransports: ['ws', 'wss'],
		transports: ['websocket', 'polling', 'flashsocket'],
		enableLogging: true,
		disableStats: true,
	})
}
